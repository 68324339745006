<template>
  <div class="about">
    <van-nav-bar
      title="物流信息"
    />
    <van-checkbox-group v-model="select" direction="horizontal" style="margin: 20px;" @change="changeSelect">
      <van-checkbox name="1">特等奖</van-checkbox>
      <van-checkbox name="2">一等奖</van-checkbox>
      <van-checkbox name="3">二等奖</van-checkbox>
      <van-checkbox name="no">无物流</van-checkbox>
    </van-checkbox-group>
    <van-cell-group>
      <van-cell v-for="(item, index) in list" v-if="item.user && item.prize" :title="item.prize.name" >
        <template #label v-if="item.user.tel">
          {{item.user.realname}},{{item.user.tel}},{{item.user.province + item.user.city + item.user.county + item.user.address}}
        </template>
        <template #label v-else="item.user.tel">
          暂无收货地址
        </template>
        <template #right-icon v-if="!item.result">
          <van-icon name="scan" @click="scan(index, item)" />
        </template>
        <template #right-icon v-else>
          <span @click="scan(index, item)">{{item.result}}</span>
        </template>
      </van-cell>
    </van-cell-group>
  </div>
</template>
<script>
import { getOrder, share, saveOrder } from '@/api/app'
import { NavBar as VanNavBar, Cell as VanCell, CellGroup as VanCellGroup, Icon as VanIcon, Dialog, Checkbox as VanCheckbox, CheckboxGroup as VanCheckboxGroup } from 'vant';
import 'vant/lib/nav-bar/style'
import 'vant/lib/checkbox/style'
import 'vant/lib/checkbox-group/style'
import wx from 'weixin-js-sdk'

export default {
  name: 'Home',
  data() {
    return {
      list: [],
      select: ['1', 'no'],
      isLoad: false
    }
  },
  components: {
   VanNavBar, VanCell, VanCellGroup, VanIcon, Dialog, VanCheckbox, VanCheckboxGroup
  },
  watch: {
  },
  created () {
    getOrder({select: this.select}).then(res=> {
      this.list = res
      this.getShare()
    })
  },
  methods: {
    scan(i, item){
      if(!item.user.tel) {
        Dialog.alert({
          title: '注意',
          message: '暂无收货地址',
        })
        return
      }
      let msg = '您要给《'+ item.prize.name +'》奖品，姓名为：《' + item.user.realname + '》录入订单号吗？';
      if(item.result) {
        msg = '订单号已经存在:' + item.result +'，您确定要修改吗？'+ msg
      }
      let that = this
      Dialog.alert({
        title: '注意',
        message: msg,
        showCancelButton: true
      }).then(() => {
        wx.scanQRCode({
            needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
            scanType: ["qrCode","barCode"], // 可以指定扫二维码还是一维码，默认二者都有
            success: function (res) {
              var result = res.resultStr.replace('CODE_128,', ''); // 当needResult 为 1 时，扫码返回的结果
              saveOrder({id: item.id, result}).then(res=> {
                if(res.code == 1) {
                  Dialog.alert({
                    title: '注意',
                    message: '录入成功:' + result
                  })
                  item.result = result
                  that.$set(that.list, index, item)
                }else{
                  Dialog.alert({
                    title: '注意',
                    message: '录入失败！'
                  })
                }
              })
            }
        });
        
      });
    },
    changeSelect(){
      if(this.isLoad)return false
      this.isLoad = true
      getOrder({select: this.select}).then(res=> {
        this.list = res
        this.isLoad = false
      })
    },
    getShare() {
      share({url: location.href.split('#')[0]}).then(res=> {
        wx.config(JSON.parse(res))
        let _this = this
        console.log(111222)
        wx.error(function(res){
          console.log(res)
        });
        wx.ready(function(){
          


          
        })
      })
    }
  }
};
</script>
<style>
.about{text-align: left;}
</style>
