<template>
  <div class="about">
    <h1>This is an about page</h1>
  </div>
</template>
<script>
import { removeUser } from '@/utils/user'
import { removeToken } from '@/utils/auth'
import { oauthUrl, setAppId, getAppVersion, setAppVersion, getAppId, removeApp, removeAppId, removeAppVersion } from '@/utils/app'

export default {
  name: 'Home',
  components: {
  	Loading
  },
  created () {
  	removeUser()
  	removeToken()
  	removeApp()
  	removeAppId()
  	
  }
};
</script>
